import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const SelectPublicoAlvo = ({ publicoAlvo, setPublicoAlvo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current &&
        !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const options = [
    { id: "BENEFICIARIO", label: "Beneficiário" },
    { id: "COOPERADO", label: "Cooperado" },
    { id: "COLABORADOR", label: "Colaborador" },
    { id: "PRESTADOR", label: "Prestador" },
    { id: "CONTRATANTEPF", label: "Contratante PF" },
    { id: "CONTRATANTEPJ", label: "Contratante PJ" }
  ];
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const descricaoPublicoAlvo = (publicos) => {
    let descricaoPublico = "";
    let sep = "";
    for (var i = 0; i < publicos.length; i++) {
      for (let j = 0; j < options.length; j++) {
        if (String(options[j].id) === String(publicos[i])) {
          descricaoPublico += sep + options[j].label;
          sep = ", ";
        }
      }
    }
    return descricaoPublico;
  }

  const handleOptionChange = (event) => {
    const optionId = String(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      setPublicoAlvo([...publicoAlvo, optionId]);
    } else {
      setPublicoAlvo(publicoAlvo.filter((id) => String(id) !== optionId));
    }
  };

  return (
    <div ref={dropdownRef} className={`dropdown ${isOpen ? 'show' : ''}`}>
      <div className="d-flex">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="multiSelectDropdown"
          onClick={toggleDropdown}
        >
          Selecione
        </button>
        <Form.Control type="text" value={descricaoPublicoAlvo(publicoAlvo)} />
      </div>
      <div style={{ width: "20%" }} className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="multiSelectDropdown">
        {options.map((option, index) => (
          <Form.Check
            style={{ marginLeft: "10%" }}
            key={index}
            type="checkbox"
            id={`option_${option.id}`}
            label={option.label}
            checked={publicoAlvo.includes(option.id)}
            onChange={handleOptionChange}
            value={option.id}
          />
        ))}
      </div>

    </div>
  );
};

export default SelectPublicoAlvo;