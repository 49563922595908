import React from 'react'
import DateUtils from '../../componentes/Utils/DateUtils.tsx';

const TabelaListaDePresenca = (props) => {


    const linhasRegistrosPresenca = props.registrosPresencas.map((presenca, index) => {
        return <tr key={index}>
            <td>{presenca.descricaoEvento}</td>
            <td>{presenca.atividade}</td>
            <td>{presenca.nome}</td>
            <td>{DateUtils.formatarDataHora(presenca.data)}</td>

        </tr>
    });

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead >
                    <tr className="green-text">
                        <th>Evento</th>
                        <th>Atividade</th>
                        <th>Nome</th>
                        <th>Data Presença</th>

                    </tr>
                </thead>
                <tbody>
                    {linhasRegistrosPresenca}
                </tbody>
            </table>
        </div>
    )
}

export default TabelaListaDePresenca;