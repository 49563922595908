 const PainelAtivoEnum = {
    CONFIGURACOES: "CONFIGURACOES",
    LISTADEPRESENCA: "LISTADEPRESENCA",
    REGISTROPRESENCA: "REGISTROPRESENCA",
    REGISTROMANUAL: "REGISTROMANUAL",
    PAINELCRUDEVENTO: "PAINELCRUDEVENTO",
    PAINELFORMEVENTO: "PAINELFORMEVENTO",
    PAINELFORMPROGRAMACAO: "PAINELFORMPROGRAMACAO",
    PAINELCRUDPROGRAMACAO: "PAINELCRUDPROGRAMACAO",
    PAINELINSCRICOES: "PAINELINSCRICOES",
    PAINELCRUDGEVPESSOA: "PAINELCRUDGEVPESSOA",
    PAINELFORMGEVPESSOA: "PAINELFORMGEVPESSOA",
    SCANNER: "SCANNER",
    NENHUM: "NENHUM"
}

export default PainelAtivoEnum;
