import React, { useEffect, useState } from "react";
import logoUnimed from '../../assets/images/logo-unimed.png';
import calendario from '../../assets/images/calendario.svg';
import badge from '../../assets/images/badge-check.svg';
import gps from '../../assets/images/gps.svg';
import telefonista from '../../assets/images/telefonista.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { ISuaInscricao } from "../../interfaces/ISuaInscricao";
import "./PaginaSuaInscricao.css";

export function PaginaSuaInscricao () {

    const [inscricao, setInscricao] = useState<ISuaInscricao>();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const { inscricao } = location.state || {};
        if (inscricao) {
            setInscricao(inscricao);
        } else {
            navigate("/inscricao-evento");
        }
    }, [location, navigate]);

    return (
        <div className="card-inscricao">
            <img src={logoUnimed} className="logo-unimed" alt="logo-unimed" />
            <div className="card">
                <h2 className="titulo">Inscrição realizada com sucesso! <img src={badge} alt="badge" /></h2>
                <div className="sobre-inscricao">
                    <div>
                        <p className="numero-inscricao">Nº da inscrição: {inscricao?.inscricaoId}</p>
                        <p>{inscricao?.nome}</p>
                        <p className="descricao-evento">{inscricao?.descricaoEvento}{inscricao?.descricaoAtividade && ` - ${inscricao.descricaoAtividade}`}</p>
                        <p className="data"><img src={calendario} alt="calendario" />{inscricao?.dataAbertura} até {inscricao?.dataFim}</p>
                        <p className="local"><img src={gps} alt="gps" />{inscricao?.localEvento}</p>
                        <p className="organizacao"><img src={telefonista} alt="telefonista" />{inscricao?.contatoOrganizacao}</p>
                    </div>
                    <div>
                        <p>As credenciais foram enviadas para o e-mail informado na inscrição. Em caso de dúvidas, contate o organizador.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}