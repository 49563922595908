import React from "react";
import moment from 'moment';

const formatarData = (datavalue: moment.MomentInput, formato: string | undefined) => {
    if (moment(datavalue).isValid()) {
        return moment(datavalue).format(formato);
    }
    else {
        return "";
    }
}

const formatarDataHora = (datavalue: moment.MomentInput) => {
    if (moment(datavalue).isValid()) {
        return moment(datavalue).format('DD/MM/YYYY HH:mm:ss');
    }
    else {
        return "";
    }
}

function getDate(dateString: moment.MomentInput, format: string | undefined) {
    let dateMomentObject = moment(dateString, format);
    let dateObject = dateMomentObject.toDate();
    return dateObject;
}

const DateUtils = {
    getDate,
    formatarData,
    formatarDataHora
}

export default DateUtils;
