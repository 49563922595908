import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import IEvento from '../../interfaces/IEvento.tsx';
import IPaginaEvento from '../../interfaces/IPaginaEvento.tsx';
import IFiltroEvento from '../../interfaces/IFiltroEvento.tsx';
import PainelAtivoEnum from '../../enums/PainelAtivoEnum.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import Pagination from "../../componentes/Pagination/Pagination.js";
import deleteSvg from '../../assets/images/delete.svg';
import editSvg from '../../assets/images/edit.svg';
import agendaPencilSvg from '../../assets/images/agenda-pencil.svg';
import peopleMultipleSvg from '../../assets/images/people-multiple.svg';
import PeopleGroupSvg from '../../assets/images/users-people.svg';
import IPaginaPessoa from '../../interfaces/IPaginaPessoa.tsx';
import IFiltroPessoa from '../../interfaces/IFiltroPessoa.tsx';

const PainelCrudGevPessoa = ({ setLoading, setPainelAtivo, setPessoa, pessoa, setError }) => {

    const LIMIT = 10;
    const [pagina, setPagina] = useState(1);
    const [stringPesquisa, setStringPesquisa] = useState("");
    const [ordem, setOrdem] = useState("nome");
    const [resultApi, setResultApi] = useState<IPaginaPessoa>({});

    useEffect(() => {
        atualizarListaPessoas();
    }, [pagina]);


    async function atualizarListaPessoas() {
        try {
            setError("");
            setResultApi({});
            setLoading(true);
            const data = await CallbackApiService.listarPessoas(getFiltro());
            setResultApi(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar lista de eventos: " + error);
            setLoading(false);
        }
    }

    function listar() {
        if (pagina !== 1) {
            setPagina(1); //ao alterar a pagina já executa a busca
        } else {
            atualizarListaPessoas();
        }
    }

    function getFiltro() {
        let filtro = {} as IFiltroPessoa;
        filtro.pagina = pagina;
        filtro.qtdeRegistrosPagina = LIMIT;
        filtro.termo = stringPesquisa;
        filtro.ordem = ordem;
        return filtro;
    }

    function onAddPessoa() {
        setPessoa({});
        setPainelAtivo(PainelAtivoEnum.PAINELFORMGEVPESSOA);
    }

    function onEditPessoa(pessoa) {
        setPessoa(pessoa);
        setPainelAtivo(PainelAtivoEnum.PAINELFORMGEVPESSOA);
    }



    async function onDeletePessoa(pessoa) {
        await CallbackApiService.deletarPessoa(pessoa)
        atualizarListaPessoas();
    }

    const LinhasPessoas = ({ pessoas }) => (
        <>
            {Array.isArray(pessoas) && pessoas.map((item, i) => (
                < >
                    <tr key={i}>
                        <td className="text-start">
                            {item.nome}
                        </td>
                        <td className="text-start">
                            {item.cpf}
                        </td>
                        <td className="text-start">
                            {item.email}
                        </td>
                        <td className="text-start">
                            {item.fone}
                        </td>
                        <td className="text-center">
                            <img src={editSvg} className="img-icon-svg" onClick={() => onEditPessoa(item)} />
                        </td>
                        <td className="text-center">
                            <img src={deleteSvg} className="img-icon-svg" onClick={() => onDeletePessoa(item)} />
                        </td>
                    </tr>
                </>
            ))}
        </>
    );


    const ListPessoas = ({ pessoas }) => (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead >
                    <tr key={0} className="green-text" >
                        <th className="text-start">Nome</th>
                        <th className="text-start">CPF</th>
                        <th className="text-start">Email</th>
                        <th className="text-start">Fone</th>
                        <th className="text-center">Alterar</th>
                        <th className="text-center">Deletar</th>
                    </tr>
                </thead>
                <tbody>
                    {pessoas && <LinhasPessoas pessoas={pessoas} />}
                </tbody>
            </table>
        </div>
    );


    return (
        <section className="painel-eventos">
            <div className="titulo-painel ">
                <h2>Cadastrar Pessoas</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <Form>
                        <div className="row" >
                            <div className="row" >
                                <div className="col-md-6 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Texto Pesquisa</Form.Label>
                                        <Form.Control type="text" onChange={(event) => setStringPesquisa(event.target.value)} value={stringPesquisa} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="d-grid gap-1">
                                        <label>&nbsp;</label>
                                        <Button variant="primary" className="botao-custom-unimed" onClick={() => listar()}>Pesquisar </Button>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="d-grid gap-1">
                                        <label>&nbsp;</label>
                                        <Button variant="primary" className="botao-custom-unimed" onClick={() => onAddPessoa()}>Incluir </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Form>
                    <div className="row">
                        {resultApi && <ListPessoas pessoas={resultApi.pessoas} />}
                        {resultApi.pessoas && <div className="col d-flex align-items-center">Total de Registros: {resultApi.qtdeRegistrosTotais} </div>}
                        {resultApi.pessoas && <Pagination limit={LIMIT} total={resultApi.qtdeRegistrosTotais} pagina={pagina} setPagina={setPagina} />}
                        <div className="row"><br /><br /></div>
                    </div>
                </div>
            </div >
        </section >
    );

}

export default PainelCrudGevPessoa;