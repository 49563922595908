const desv = {
  url: {
    KEYCLOAK_BASE_URL: "https://keycloak.unimedgoiania.coop.br",
    KEYCLOAK_HEALM: "homologation",
    KEYCLOAK_CLIENT_ID: 'registrar-presenca',
    API_BASE_URL: 'http://localhost:8080'
  }
}

const prod = {
  url: {
    KEYCLOAK_BASE_URL: "https://keycloak.unimedgoiania.coop.br",
    KEYCLOAK_HEALM: process.env.REACT_APP_KEYCLOAK_HEALM,
    KEYCLOAK_CLIENT_ID: 'registrar-presenca',
  }
}
export const config = process.env.NODE_ENV === 'development' ? desv : prod