import React from 'react';
import './Pagination.css';
import Pagination from 'react-bootstrap/Pagination';

const MAX_ITEMS = 9;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

const CustomPagination = ({
  limit,
  total,
  pagina,
  setPagina
}) => {
  const current = pagina ? pagina : 1;
  const pages = Math.ceil(total / limit);
  const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1);
  const first = Math.min(
    Math.max(current - MAX_LEFT, 1),
    maxFirst
  );

  function onPageChange(page) {
    setPagina(page);
  }

  return (
    <div className='adireita'>
      <Pagination>
        <Pagination.Prev
          onClick={() => onPageChange(current - 1)}
          disabled={current === 1}
        />
        {Array.from({ length: Math.min(MAX_ITEMS, pages) })
          .map((_, index) => index + first)
          .map((page) => (
            <Pagination.Item
              active={current === page}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}
        <Pagination.Next
          onClick={() => onPageChange(current + 1)}
          disabled={current === pages}
        />
      </Pagination>
    </div>
  );
};

export default CustomPagination;