
import PropTypes from 'prop-types'
import keycloak from "../KeycloakProvider";
import NotAllowed from "./NotAllowed/NotAllowed";

function hasRole (roles) {
  const result = roles.some((role) => keycloak.hasResourceRole(role));
  return result;
}

const RenderOnRole = ({ roles, showNotAllowed, children }) => (
  hasRole(roles)) ? children : showNotAllowed ? <NotAllowed /> : null;

RenderOnRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RenderOnRole
