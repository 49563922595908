
function validarCpf(value: string) {
    if (!value)
        return value;
    // remove caracteres nao numericos
    let cpf = String(value.replace(/[^0-9]+/g, ""));
    // completar com zeros a esquerada
    cpf = ("00000000000" + cpf).slice(-11);
    let soma: number = 0;
    let resto: number = 0;
    for (let i = 1; i <= 9; i++) {
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    }
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) {
        resto = 0;
    }
    console.log("Cpf:"+cpf+ "  Digito1:"+resto);
    if (resto !== parseInt(cpf.substring(9, 10))) {
        return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
        soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
    }
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) {
        resto = 0;
    }
    console.log("Cpf:"+cpf+ "  Digito2:"+resto);
    if (resto !== parseInt(cpf.substring(10, 11))) {
        return false;
    }
    return true;
}



function formatarDigitos(value: string, mascara: string) {
    if (!value) {
        return value;
    }
    // remove caracteres nao numericos
    let digitos = String(value.replace(/[^0-9]+/g, ""));
    let indMascara = 0;
    let indCampo = 0;
    let digitoMascara = "";
    let saida = "";
    while (indCampo < digitos.length) {
        if (indMascara < mascara.length) {
            digitoMascara = mascara.charAt(indMascara);
        } else {
            digitoMascara = "#";
        }

        if (digitoMascara === '#') {
            saida = saida + digitos.charAt(indCampo++)
        } else {
            saida = saida + mascara.charAt(indMascara)
        }
        indMascara++;
    }
    return saida;
}


const Utils = {
    validarCpf,
    formatarDigitos
}

export default Utils;