import React, { useEffect, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import PainelAtivoEnum from '../../enums/PainelAtivoEnum.tsx';
import DateUtils from '../../componentes/Utils/DateUtils.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import IProgramacao from '../../interfaces/IProgramacao.tsx';

const DTF: string = 'YYYY-MM-DD HH:mm';
const DTFBACK: string = 'YYYY-MM-DD HH:mm';
const DTFTELA: string = 'DD/MM/YYYY HH:mm'
const PainelFormProgramacao = ({ setLoading, setPainelAtivo, evento, programacao, setError }) => {

    const [validated, setValidated] = useState(false);
    const [dataAberturaInvalida, setDataAberturaInvalida] = useState(false);
    const [dataFimInvalida, setDataFimInvalida] = useState(false);
    const [id, setId] = useState<string | undefined>("");
    const [atividade, setAtividade] = useState<string | undefined>("");
    const [dataAbertura, setDataAbertura] = useState<string | undefined>("");
    const [dataInicio, setDataInicio] = useState<string | undefined>("");
    const [dataFim, setDataFim] = useState<string | undefined>("");
    const [instrutor, setInstrutor] = useState<string | undefined>("");
    const [cargaHoraria, setCargaHoraria] = useState<string | undefined>("");
    const [limiteInscricoes, setLimiteInscricoes] = useState<number | undefined>();

    const [resultApi, setResultApi] = useState<IProgramacao>({});

    useEffect(() => {
        setId(programacao.id);
        setAtividade(programacao.atividade);
        setDataAbertura(DateUtils.formatarData(DateUtils.getDate(programacao.dataAbertura, DTFBACK), DTF));
        setDataInicio(DateUtils.formatarData(DateUtils.getDate(programacao.dataInicio, DTFBACK), DTF));
        setDataFim(DateUtils.formatarData(DateUtils.getDate(programacao.dataFim, DTFBACK), DTF))
        setInstrutor(programacao.instrutor);
        setCargaHoraria(programacao.cargaHoraria);
        setLimiteInscricoes(programacao.limiteInscricoes);
    }, []);

    useEffect(() => {
        function verificarDatas() {
            let dataA = DateUtils.getDate(dataAbertura, DTF);
            let dataI = DateUtils.getDate(dataInicio, DTF);
            let dataF = DateUtils.getDate(dataFim, DTF);
            if (dataA >= dataI) {
                setDataAberturaInvalida(true);
            } else {
                setDataAberturaInvalida(false);
            }
            if (dataF <= dataI) {
                setDataFimInvalida(true);
            } else {
                setDataFimInvalida(false);
            }
        }
        verificarDatas();
    }, [dataAbertura, dataInicio, dataFim]);

    const onSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true && !dataAberturaInvalida && !dataFimInvalida) {
            setValidated(true);
            salvar();
        }
    }

    async function salvar() {
        try {
            setLoading(true);
            setError("");
            let requestData = {} as IProgramacao;
            requestData.id = Number(id);
            requestData.eventoId = evento.id;
            requestData.atividade = atividade;
            requestData.dataAbertura = DateUtils.formatarData(DateUtils.getDate(dataAbertura, DTF), DTFBACK);
            requestData.dataInicio = DateUtils.formatarData(DateUtils.getDate(dataInicio, DTF), DTFBACK);
            requestData.dataFim = DateUtils.formatarData(DateUtils.getDate(dataFim, DTF), DTFBACK);
            requestData.instrutor = instrutor;
            requestData.cargaHoraria = cargaHoraria;
            requestData.limiteInscricoes = limiteInscricoes;
            const data = await CallbackApiService.salvarProgramacao(requestData);
            setPainelAtivo(PainelAtivoEnum.PAINELCRUDPROGRAMACAO);
        } catch (error) {
            console.log("Error:" + error);
            setError(error.message);
        }
        setLoading(false);
    }

    function onHidePainel() {
        setPainelAtivo(PainelAtivoEnum.PAINELCRUDPROGRAMACAO);
    }

    return (
        <section className="painel-eventos">
            <div className="titulo-painel ">
                <h2>Programar Atividades Evento {evento.descricao}</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <Form validated={validated} onSubmit={onSubmit}>
                        <div className="row" >
                            <div className="row" >
                                <div className="col-md-2 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Id</Form.Label>
                                        <Form.Control readOnly type="text" value={programacao.id} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-10 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Atividade</Form.Label><span className="text-danger font-bold">*</span>
                                        <Form.Control type="text" required onChange={(event) => setAtividade(event.target.value)} value={atividade} />
                                        <Form.Control.Feedback type="invalid">
                                            Por Favor Informe a Descrição da Atividade.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-4 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Entrada Liberada a Partir de</Form.Label><span className="text-danger font-bold">*</span>
                                        <Form.Control type="datetime-local" required onChange={(event) => setDataAbertura(event.target.value)} value={dataAbertura} />
                                        <Form.Control.Feedback type="invalid">
                                            Por Favor Informe a Data e Hora Abertura para Entrada do Participantes na Atividade.
                                        </Form.Control.Feedback>
                                        {dataAberturaInvalida && <div className="periodo-invalido">Data Abertura deve ser menor que data de inicio</div>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Data Hora Inicio</Form.Label><span className="text-danger font-bold">*</span>
                                        <Form.Control type="datetime-local" required onChange={(event) => setDataInicio(event.target.value)} value={dataInicio} />
                                        <Form.Control.Feedback type="invalid">
                                            Por Favor Informe a Data e Hora Programada para Inicio Da Atividade.
                                        </Form.Control.Feedback>
                                        {/* {dataInicioIvalido && <div className="periodo-invalido">Data de inicio maior que data de abertura</div>} */}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Data Hora Encerramento</Form.Label><span className="text-danger font-bold">*</span>
                                        <Form.Control type="datetime-local" required onChange={(event) => setDataFim(event.target.value)} value={dataFim} />
                                        <Form.Control.Feedback type="invalid">
                                            Por Favor Informe a Data e Hora Programada para Encerramento da Atividade.
                                        </Form.Control.Feedback>
                                        {dataFimInvalida && <div className="periodo-invalido">Data de encerramento deve ser maior que Data Inicio</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-6 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Instrutor</Form.Label>
                                        <Form.Control type="text" onChange={(event) => setInstrutor(event.target.value)} value={instrutor} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Carga Horaria</Form.Label>
                                        <Form.Control type="text" onChange={(event) => setCargaHoraria(event.target.value)} value={cargaHoraria} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Limite Inscrições</Form.Label>
                                        <Form.Control type="text" onChange={(event) => setLimiteInscricoes(Number(event.target.value))} value={limiteInscricoes} />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-md-6 col-sm-6"></div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button type="submit" variant="primary" className="botao-custom-unimed" >Salvar </Button>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => onHidePainel()}>Fechar </Button>
                                </div>
                            </div>
                        </div>
                        <div className="row"><br /><br /></div>
                    </Form>
                </div>
            </div >
        </section >
    );

}

export default PainelFormProgramacao;