import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Pagination from "../../componentes/Pagination/Pagination";
import CallbackApiService from "../../services/CallbackApiService.tsx";
import DateUtils from '../../componentes/Utils/DateUtils.tsx';
import IRegistroPresenca from '../../interfaces/IRegistroPresenca.tsx';
import IPaginaRegistroPresenca from '../../interfaces/IPaginaRegistroPresenca.tsx';
import IFiltroRegistroPresenca from '../../interfaces/IFiltroRegistroPresenca.tsx';
import TabelaListaDePresenca from './TabelaListaDePresenca.tsx';
import IProgramacaoDto from '../../interfaces/IProgramacaoDto.tsx';

const DF: string = 'DD-MM-YYYY HH:mm';
const DTF: string = 'YYYY-MM-DD HH:mm';
const DTFBACK: string = 'YYYYMMDD HHmm';

const PainelListaDePresenca = ({ programacao, setLoading, setPainelAtivo, setError }) => {

    const LIMIT = 10;
    const [pagina, setPagina] = useState(1);
    const [dataInicial, setDataInicial] = useState<string | undefined>(() => getDataInicialPadrao());
    const [dataFinal, setDataFinal] = useState<string | undefined>(() => getDataFinalPadrao());
    const [periodoIvalido, setPeriodoIvalido] = useState(false);
    const [stringPesquisa, setStringPesquisa] = useState<string | undefined>("");
    const [programacaoId, setProgramacaoId] = useState<string | undefined>("");
    const [listarRepetidos, setListarRepetidos] = useState("SIM");
    const [listarAusentes, setListarAusentes] = useState("SIM");
    const [layoutRelatorio, setLayoutRelatorio] = useState("PADRAO");
    const [ordem, setOrdem] = useState<string | undefined>("data");
    const [resultApi, setResultApi] = useState<IPaginaRegistroPresenca>({});
    const [programacoes, setProgramacoes] = useState<IProgramacaoDto[]>([]);
    const [textoTotais, setTextoTotais] = useState<string | undefined>("");


    useEffect(() => {
        async function listarProgramacoes() {
            try {
                const data = await CallbackApiService.listarProgramacoes({});
                setProgramacoes(data);
                if (programacao && programacao.programacaoId) {
                    setProgramacaoId(programacao.programacaoId);
                }

            } catch (error) {
                setError(error.message);
                console.log("Falha ao buscar lista de eventos/atividades :" + error);
            }
        }
        listarProgramacoes();

    }, []);

    useEffect(() => {
        function atualizaPeriodo() {
            if (!programacaoId) {
                return;
            }
            let lg = programacoes.length;
            for (let i = 0; i < lg; i++) {
                if (String(programacoes[i].programacaoId) === String(programacaoId)) {
                    setDataInicial(programacoes[i].abertura);
                    setDataFinal(programacoes[i].encerramento);
                    break;
                }
            }
        }
        atualizaPeriodo();
    }, [programacaoId]);

    useEffect(() => {
        buscarRegistrosPresenca();
    }, [pagina]);

    useEffect(() => {
        function verificarPeriodo() {
            let dataI = DateUtils.getDate(dataInicial, DTF);
            let dataF = DateUtils.getDate(dataFinal, DTF);
            if (dataI > dataF) {
                setPeriodoIvalido(true);
            } else {
                setPeriodoIvalido(false);
            }
        }
        verificarPeriodo();
    }, [dataInicial, dataFinal]);

    useEffect(() => {
        function mostrarTotais() {
            if (resultApi.qtdeInscritos && resultApi.qtdePresentes) {
                setTextoTotais("Total de Inscritos: " + resultApi.qtdeInscritos + "   Total Presenças: " + resultApi.qtdePresentes + "   Percentual: " + Number(resultApi.qtdePresentes * 100 / resultApi.qtdeInscritos).toFixed(2) + "%");
            } else {
                setTextoTotais("Total de Registros: " + resultApi.qtdeRegistrosTotais);
            }
        }
        mostrarTotais();
    }, [resultApi]);

    function getDataInicialPadrao() {
        let data = new Date();
        data.setHours(data.getHours() - 6);
        return DateUtils.formatarData(data, DTF);
    }

    function getDataFinalPadrao() {
        let data = new Date();
        data.setHours(data.getHours() + 6);
        return DateUtils.formatarData(data, DTF);
    }

    async function buscarRegistrosPresenca() {
        try {
            setError("");
            setResultApi({});
            setLoading(true);
            const data = await CallbackApiService.listarRegistrosPresencas(getFiltro());
            setResultApi(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar Registros de Presença: " + error);
            setLoading(false);
        }
    }

    function listar() {
        if (pagina !== 1) {
            setPagina(1); //ao alterar a pagina já executa a busca
        } else {
            buscarRegistrosPresenca();
        }
    }

    function getFiltro() {
        let filtro = {} as IFiltroRegistroPresenca;
        filtro.pagina = pagina;
        filtro.qtdeRegistrosPagina = LIMIT;
        let dataI = DateUtils.getDate(dataInicial, DTF);
        let dataF = DateUtils.getDate(dataFinal, DTF);
        filtro.dataInicial = DateUtils.formatarData(dataI, DTFBACK).replace(" ", "");
        filtro.dataFinal = DateUtils.formatarData(dataF, DTFBACK).replace(" ", "");
        filtro.programacaoId = programacaoId;
        filtro.stringPesquisa = stringPesquisa;
        filtro.listarRepetidos = listarRepetidos;
        filtro.listarAusentes = listarAusentes;
        filtro.layoutRelatorio = layoutRelatorio;
        filtro.ordem = ordem;
        return filtro;
    }


    async function exportarXlsx() {
        try {
            setError("");
            setLoading(true);
            const arquivoRetorno = await CallbackApiService.exportarRegistrosPresencasToXlsx(getFiltro());
            setLoading(false);
            downLoadFile(arquivoRetorno.conteudoBase64, arquivoRetorno.nome, "dowload");
        } catch (error) {
            setError(error.message);
            console.log("Falha ao exportar Registros de Presença: " + error);
            setLoading(false);
        }
    }

    async function exportarPdf() {
        try {
            setError("");
            setLoading(true);
            const arquivoRetorno = await CallbackApiService.exportarRegistrosPresencasToPdf(getFiltro());
            setLoading(false);
            downLoadFile(arquivoRetorno.conteudoBase64, arquivoRetorno.nome, "dowload");
        } catch (error) {
            setError(error.message);
            console.log("Falha ao exportar Registros de Presença: " + error);
            setLoading(false);
        }
    }
    function downLoadFile(conteudoBase64, nameArq, opcao) {
        const bufferArray = base64ToArrayBuffer(conteudoBase64);
        let blob = new Blob([bufferArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let url = window.URL.createObjectURL(blob);
        if (opcao == "openNewTab") {
            let newWindow = window.open(url, '_blank');
            setTimeout(function () { newWindow.document.title = nameArq; }, 3000);
        } else {
            let a = document.createElement('a');
            a.href = url;
            a.download = nameArq;
            a.click();
        }
    }

    function base64ToArrayBuffer(conteudoBase64) {
        const bString = window.atob(conteudoBase64);
        const bLength = bString.length;
        const bytes = new Uint8Array(bLength);
        for (let i = 0; i < bLength; i++) {
            bytes[i] = bString.charCodeAt(i);
        }
        return bytes;
    }

    return (
        <section className="painel-lista-de-presenca">
            <div className="titulo-painel ">
                <h2>Lista de Presença</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <Form>
                        <div className="row" >
                            <div className="col-md-9 col-sm-12">
                                <div className="row" >
                                    <div className="col-md-12 col-sm-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Evento/Atividade</Form.Label>
                                            <Form.Select value={programacaoId} onChange={(event) => setProgramacaoId(event.target.value)}>
                                                <option value="">Todos</option>
                                                {Array.isArray(programacoes) && programacoes.map((item, i) => (
                                                    <option key={i} value={item.programacaoId}>
                                                        {item.descricaoEvento + " - " + item.atividade + " -> Programação: " + DateUtils.formatarData(DateUtils.getDate(item.abertura, DTF), DF) + " a " + DateUtils.formatarData(DateUtils.getDate(item.encerramento, DTF), DF)}
                                                    </option>
                                                ))}
                                            </Form.Select >
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Data Inicial</Form.Label>
                                            <Form.Control type="datetime-local" required onChange={(event) => setDataInicial(event.target.value)} value={dataInicial} />
                                            {periodoIvalido && <div className="periodo-invalido">Data inicial maior que data final</div>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Data Final</Form.Label>
                                            <Form.Control type="datetime-local" required onChange={(event) => setDataFinal(event.target.value)} value={dataFinal} />
                                            {periodoIvalido && <div className="periodo-invalido">Data final menor que data inicial</div>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-2 col-sm-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Layout</Form.Label>
                                            <Form.Select value={layoutRelatorio} onChange={(event) => setLayoutRelatorio(event.target.value)} >
                                                <option value="PADRAO">Padrão</option>
                                                <option value="SESCOOP">SESCOOP</option>
                                            </Form.Select >
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-2 col-sm-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Ausentes</Form.Label>
                                            <Form.Select value={listarAusentes} onChange={(event) => setListarAusentes(event.target.value)} >
                                                <option value="SIM">Sim</option>
                                                <option value="NAO">Não</option>
                                            </Form.Select >
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <Form.Label>Repetidos</Form.Label>
                                        <Form.Select value={listarRepetidos} onChange={(event) => setListarRepetidos(event.target.value)} >
                                            <option value="SIM">Sim</option>
                                            <option value="NAO">Não</option>
                                        </Form.Select >
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <Form.Label>Ordem</Form.Label>
                                        <Form.Select value={ordem} onChange={(event) => setOrdem(event.target.value)} >
                                            <option value="data">Data</option>
                                            <option value="nome">Nome</option>
                                        </Form.Select >
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Texto Pesquisa</Form.Label>
                                            <Form.Control type="text" onChange={(event) => setStringPesquisa(event.target.value)} value={stringPesquisa} />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12" >
                                <div className="row">
                                    <div className="d-grid gap-1">
                                        <label>&nbsp;</label>
                                        <Button disabled={periodoIvalido} variant="primary" className="botao-custom-unimed" onClick={() => listar()}>Pesquisar </Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="d-grid gap-1">
                                        <label>&nbsp;</label>
                                        <Button disabled={periodoIvalido} variant="primary" className="botao-custom-unimed" onClick={() => exportarXlsx()}>Exportar XLSX </Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="d-grid gap-1">
                                        <label>&nbsp;</label>
                                        <Button disabled={periodoIvalido} variant="primary" className="botao-custom-unimed" onClick={() => exportarPdf()}>Exportar PDF </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div className="row">
                        {resultApi.registrosPresencas && <TabelaListaDePresenca registrosPresencas={resultApi.registrosPresencas} />}
                        {resultApi.registrosPresencas && <div className="col d-flex align-items-center">{textoTotais} </div>}
                        {resultApi.registrosPresencas && <Pagination limit={LIMIT} total={resultApi.qtdeRegistrosTotais} pagina={pagina} setPagina={setPagina} />}
                        <div className="row"><br /><br /></div>
                    </div>
                </div>
            </div >
        </section >
    );
}

export default PainelListaDePresenca;