import React from 'react';
import { Form, Button } from 'react-bootstrap';
import DateUtils from '../../componentes/Utils/DateUtils.tsx';
import PainelAtivoEnum from '../../enums/PainelAtivoEnum.tsx';

const PainelRegistroPresenca = ({ programacao, registroPresenca, setPainelAtivo }) => {


    function onHidePainel() {
        setPainelAtivo(PainelAtivoEnum.NENHUM);
    }

    return (
        <section className="painel-registro-presenca">
            <div className="titulo-painel ">
                <h2>Registro de Presença</h2>
            </div>
            <div className="col-md-8 col-sm-12">
                <div className="box-painel">
                    <div className="row" >
                        <div className="col-md-6 col-sm-12">
                            <Form.Label>Evento</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control readOnly type="text" value={programacao.descricaoEvento} />
                            </Form.Group>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Form.Label>Atividade</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control readOnly type="text" value={programacao.atividade} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-md-6 col-sm-12">
                            <Form.Label>Data Presença</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control readOnly type="text" value={DateUtils.formatarDataHora(registroPresenca.data)} />
                            </Form.Group>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Form.Label>Nome</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control readOnly type="text" value={registroPresenca.nome} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-md-6 col-sm-12">
                            <Form.Label>CPF Pessoa</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control readOnly type="text" value={registroPresenca.cpf} />
                            </Form.Group>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Form.Label>PessoaId</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control readOnly type="text" value={registroPresenca.pessoaId} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-4">
                            <div className="d-grid gap-1">
                                <Button variant="primary" className="botao-custom-unimed" onClick={() => onHidePainel()}>OK </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PainelRegistroPresenca;