import React from 'react'
import { useRef, useEffect, useState } from 'react';
import { Tooltip, Button } from 'react-bootstrap';
import CallbackApiService from '../../services/CallbackApiService.tsx';
import "./FormFileUpload.css";



const FormFileUpload = ({ arquivoImgPerfilId, setArquivoImgPerfilId, setLoading }) => {

    const hiddenFileInput = useRef(null);
    const [error, setError] = useState({});
    const [fileImgData, setFileImgData] = useState<string | undefined>("");

    useEffect(() => {
        async function atualizarImagemArquivo() {
            if (!arquivoImgPerfilId) {
                return;
            }
            try {
                const data = await CallbackApiService.buscarArquivo(arquivoImgPerfilId);
                if (data.fileDataBase64) {
                    setFileImgData('data:image/jpeg;base64,' + data.fileDataBase64);
                }
            } catch (error) {
                setError(error.message);
                console.log("Falha ao buscar arquivo imagem perfil" + error);
            }
        }
        atualizarImagemArquivo();
    }, [arquivoImgPerfilId]);

    const handleClick = event => {
        console.log("clicou no upload....");
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        console.log("Iniciando upload do arquivo....");
        const fileUploaded = event.target.files[0];
        salvarArquivo(fileUploaded);
    };

    async function salvarArquivo(file) {
        setLoading(true);
        try {
            const data = await CallbackApiService.salvarArquivo(null, file);
            setArquivoImgPerfilId(data.id);
            console.log("Finalizado uploado do arquivo para o banco de dados");
        } catch (error) {
            console.log("Error:" + error);
            setError(error.message);
        }
        setLoading(false);
    };

    return (
        <div>
            {!fileImgData &&
                <div className="d-grid gap-1">
                    <Button variant="primary" className="botao-custom-unimed" data-toggle="tooltip" data-placement="top" title="Importar Arquivo Imagem" onClick={handleClick}>Adicionar Imagem</Button>
                </div>
            }
            {fileImgData &&
                <img src={fileImgData} className="img-perfil" onClick={handleClick} data-toggle="tooltip" data-placement="top" title="Importar Arquivo Imagem" />
            }
            <input
                type="file"
                accept='.jpg, .jpeg, .png'
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default FormFileUpload;