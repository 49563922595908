import Keycloak from 'keycloak-js'
import { config} from "./Constants.tsx"
import { useEffect } from 'react';

function getKeyClockRealm() {
  let urlHost = `${window.location.host.toString()}`;
  if (["localhost", "dockerdev", "apist-vm", "apihom.unimed"].some(url => urlHost.toLowerCase().indexOf(url) !== -1)) {
    return "homologation";
  }
  return "production";
};



const keycloakConfig = {
  url: `${config.url.KEYCLOAK_BASE_URL}`,
  realm:  getKeyClockRealm(),
  clientId: `${config.url.KEYCLOAK_CLIENT_ID}`
}


const keycloak =  new Keycloak(keycloakConfig);


keycloak.onTokenExpired = () => {
  console.log('Keycloak Token Expirado Em: ' + new Date());
  keycloak.updateToken(5).then(function (refreshed) {
    if (refreshed) {
      console.log('Token Atualizado com Sucesso EM:' + new Date());
    } else {
      console.log('Token is still valid');
    }
  }).catch(function (error) {
    console.log('Falha ao atualizar o token ou a sessão expirou:' + error);
  });
}

try {
  const authenticated = await keycloak.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
  });

  console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
} catch (error) {
  console.error('Failed to initialize adapter:', error);
}

export default keycloak;
