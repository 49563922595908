import React, { useEffect, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import IEvento from '../../interfaces/IEvento.tsx';
import IPaginaProgramacao from '../../interfaces/IPaginaProgramacao.tsx';
import IFiltroProgramacao from '../../interfaces/IFiltroProgramacao.tsx';
import PainelAtivoEnum from '../../enums/PainelAtivoEnum.tsx';
import DateUtils from '../../componentes/Utils/DateUtils.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import Pagination from "../../componentes/Pagination/Pagination.js";
import deleteSvg from '../../assets/images/delete.svg';
import editSvg from '../../assets/images/edit.svg';
import IProgramacao from '../../interfaces/IProgramacao.tsx';

const DTF: string = 'YYYY-MM-DD HH:mm';
const DTFBACK: string = 'YYYY-MM-DD HH:mm';
const DTFTELA: string = 'DD/MM/YYYY HH:mm'
const PainelCrudProgramacao = ({ setLoading, setPainelAtivo, evento, setProgramacao, setError }) => {


    const LIMIT = 5;
    const [pagina, setPagina] = useState(1);
    const [validated, setValidated] = useState(false);
    const [resultApi, setResultApi] = useState<IPaginaProgramacao>({});
    const [itemProgramacao, setItemProgramacao] = useState<IProgramacao>({});
    const [ordem, setOrdem] = useState<string | undefined>("dataInicio");
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [dataInicioIvalido, setDataInicioIvalido] = useState(false);
    const [id, setId] = useState<string | undefined>("");
    const [atividade, setAtividade] = useState<string | undefined>("");
    const [dataAbertura, setDataAbertura] = useState<string | undefined>("");
    const [dataInicio, setDataInicio] = useState<string | undefined>("");
    const [dataFim, setDataFim] = useState<string | undefined>("");

    const inputRefAtividade = useRef(null);



    useEffect(() => {
        listar();
    }, []);

    useEffect(() => {
        atualizarListaProgramacao();
    }, [pagina]);


    async function atualizarListaProgramacao() {
        try {
            setError("");
            setResultApi({});
            setLoading(true);
            const data = await CallbackApiService.listarProgramacao(getFiltro());
            setResultApi(data);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            console.log("Falha ao buscar lista de eventos: " + error);
            setLoading(false);
        }
    }

    function listar() {
        if (pagina !== 1) {
            setPagina(1); //ao alterar a pagina já executa a busca
        } else {
            atualizarListaProgramacao();
        }
    }

    function getFiltro() {
        let filtro = {} as IFiltroProgramacao;
        filtro.pagina = pagina;
        filtro.qtdeRegistrosPagina = LIMIT;
        filtro.eventoId = evento.id;
        filtro.ordem = ordem;
        return filtro;
    }

    function onAddProgramacaoEvento() {
        let programacao = {} as IProgramacao;
        programacao.atividade = "";
        programacao.dataAbertura = "";
        programacao.dataInicio = "";
        programacao.dataFim = "";
        setProgramacao(programacao);
        setPainelAtivo(PainelAtivoEnum.PAINELFORMPROGRAMACAO);
    }

    function onEditItemProgramacao(item) {
        setProgramacao(item);
        setPainelAtivo(PainelAtivoEnum.PAINELFORMPROGRAMACAO);
    }


    async function onDeleteProgramacao(item) {
        try {
            setError("");
            let data = await CallbackApiService.deletarProgramacao(item);
            listar();
        } catch (error) {
            console.log("Error:" + error);
            setError(error.message);
        }
        
    }

    function onHidePainel() {
        setPainelAtivo(PainelAtivoEnum.PAINELCRUDEVENTO);
    }

    const LinhasItemProgramacao = ({ programacao }) => (
        <>
            {Array.isArray(programacao) && programacao.map((item, i) => (
                < >
                    <tr key={i}>
                        <td className="text-start">
                            {item.atividade}
                        </td>
                        <td className="text-start">
                            {DateUtils.formatarData(DateUtils.getDate(item.dataAbertura, DTFBACK), DTFTELA)}
                        </td>
                        <td className="text-start">
                            {DateUtils.formatarData(DateUtils.getDate(item.dataInicio, DTFBACK), DTFTELA)}
                        </td>
                        <td className="text-start">
                            {DateUtils.formatarData(DateUtils.getDate(item.dataFim, DTFBACK), DTFTELA)}
                        </td>
                        <td className="text-center">
                            <img src={editSvg} className="img-icon-svg" onClick={() => onEditItemProgramacao(item)} />
                        </td>
                        <td className="text-center">
                            <img src={deleteSvg} className="img-icon-svg" onClick={() => onDeleteProgramacao(item)} />
                        </td>
                    </tr>
                </>
            ))}
        </>
    );


    const ListProgramacao = ({ programacao }) => (

        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead ><tr><th colSpan={6} className="text-center">Atividades Programadas para o Evento</th> </tr> </thead>
                <thead >
                    <tr key={0} className="green-text" >
                        <th className="text-start">Atividade</th>
                        <th className="text-start">Entrada Liberada</th>
                        <th className="text-start">Data Hora Inicio</th>
                        <th className="text-start">Data Hora Encerramento</th>
                        <th className="text-center">Alterar</th>
                        <th className="text-center">Deletar</th>
                    </tr>
                </thead>
                <tbody>
                    {programacao && <LinhasItemProgramacao programacao={programacao} />}
                </tbody>
            </table>
        </div>
    );

    return (
        <section className="painel-eventos">
            <div className="titulo-painel ">
                <h2>Programar Atividades Evento {evento.descricao}</h2>
            </div>
            <div className="col-md-10 col-sm-12">
                <div className="box-painel">
                    <Form>

                        <div className="row" >
                            <div className="col-md-6 col-sm-6"></div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => onAddProgramacaoEvento()}>Nova Atividade </Button>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => onHidePainel()}>Fechar </Button>
                                </div>
                            </div>
                        </div>
                        <div className="row"><br /><br /></div>
                    </Form>
                    <div className="row">
                        {resultApi && <ListProgramacao programacao={resultApi.programacaoList} />}
                        <div className="row">
                            {resultApi.programacaoList && <div className="col d-flex align-items-center">Total de Registros: {resultApi.qtdeRegistrosTotais} </div>}
                            {resultApi.programacaoList && <Pagination limit={LIMIT} total={resultApi.qtdeRegistrosTotais} pagina={pagina} setPagina={setPagina} />}
                        </div>
                        <div className="row"><br /><br /></div>
                    </div>
                </div>
            </div >
        </section >
    );

}

export default PainelCrudProgramacao;