
import React, { useEffect } from 'react'
import {Route, Routes, useLocation } from "react-router-dom";
import keycloak from "./KeycloakProvider";
import './App.css';
import RenderOnRole from "./componentes/RenderOnRole";
import PaginaGestaoEventos from './pages/gestaoeventos/PaginaGestaoEventos.tsx';
import { PaginaInscricaoEvento } from './pages/inscricao/PaginaInscricaoEvento.tsx';
import { PaginaSuaInscricao } from './pages/inscricao/PaginaSuaInscricao.tsx';

function App() {

  const location = useLocation(); //para utilizar neste contexto deve estar dentro do contexto de BrowserRouter(ver index.js) 
  const path = location.pathname;

  useEffect(() => {
    if (!keycloak.authenticated && !(path || "").includes("inscricao-evento") && !(path || "").includes("sua-inscricao")) {
      keycloak.login();
    }
  }, [keycloak]);
      
  return (
    <div id="wrapper ">
        <Routes>
          <Route exact path="/" element={
            <RenderOnRole roles={['usuario']} showNotAllowed>
              <PaginaGestaoEventos />
            </RenderOnRole>}
          />
          <Route
            path="/index"
            element={
              <RenderOnRole roles={['usuario']} showNotAllowed>
                <PaginaGestaoEventos />
              </RenderOnRole>
            }
          />
          <Route
            path="/inscricao-evento"
            element={<PaginaInscricaoEvento />}
          />
          <Route
            path="/sua-inscricao"
            element={<PaginaSuaInscricao />}
          />
        </Routes>
      </div>
  );
}

export default App;