import React, { useState, useEffect } from 'react';
import "./PaginaGestaoEventos.css";
import QrReader from "../../componentes/QrCode/QrReader.tsx"
import IRegistroPresencaRequest from '../../interfaces/IRegistroPresencaRequest.tsx';
import IRegistroPresenca from "../../interfaces/IRegistroPresenca.tsx"
import IEvento from '../../interfaces/IEvento.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import LoadingWait from "../../componentes/Loading/Loading.js";
import PainelRegistroPresenca from './PainelRegistroPresenca.tsx';
import PainelConfiguracoes from './PainelConfiguracoes.tsx';
import PainelAtivoEnum from '../../enums/PainelAtivoEnum.tsx'
import PainelListaDePresenca from './PainelListaDePresenca.tsx';
import PainelRegistroManual from './PainelRegistroManual.tsx';
import PainelInscricoes from '../crud-evento/PainelInscricoes.tsx';
import logoUnimed from '../../assets/images/logo-unimed.png';
import settingsSvg from '../../assets/images/settings.svg';
import formSvg from '../../assets/images/form.svg';
import scanSvg from '../../assets/images/scan-qrcode.svg';
import userCheckSvg from '../../assets/images/user-check.svg';
import PainelFormEvento from '../crud-evento/PainelFormEvento.tsx';
import PainelCrudGevPessoa from '../crud-pessoa/PainelCrudGevPessoa.tsx';
import PainelFormGevPessoa from '../crud-pessoa/PainelFormGevPessoa.tsx';
import IGevPessoa from '../../interfaces/IGevPessoa.tsx';
import IProgramacaoDto from '../../interfaces/IProgramacaoDto.tsx';
import PainelCrudEvento from '../crud-evento/PainelCrudEvento.tsx';
import PainelCrudProgramacao from '../crud-evento/PainelCrudProgramacao.tsx';
import IProgramacao from '../../interfaces/IProgramacao.tsx';
import PainelFormProgramacao from '../crud-evento/PainelFormProgramacao.tsx';

const PaginaGestaoEventos = () => {

  const [evento, setEvento] = useState<IEvento>({});
  const [programacaoEdit, setProgramacaoEdit] = useState<IProgramacao>({});
  const [programacao, setProgramacao] = useState<IProgramacaoDto>({});
  const [pessoa, setPessoa] = useState<IGevPessoa>({});
  const [scannedResult, setScannedResult] = useState<string | undefined>("");
  const [painelAtivo, setPainelAtivo] = useState<string | undefined>("");
  const [monitoramento, setMonitoramento] = useState<string | undefined>("OPERADOR");
  const [registroPresenca, setRegistroPresenca] = useState<IRegistroPresenca>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    async function registrarPresenca() {
      try {
        if (qrCodeValido(scannedResult)) {
          let requestData = {} as IRegistroPresencaRequest;
          requestData.programacaoId = programacao.programacaoId;
          requestData.qrCode = scannedResult;
          setLoading(true);
          const data = await CallbackApiService.registrarPresencaPorQrCode(requestData);
          setRegistroPresenca(data);
          setPainelAtivo(PainelAtivoEnum.REGISTROPRESENCA);
        }
      } catch (error) {
        console.log("Error:" + error);
        setError(error.message);
      }
      setLoading(false);
      setScannedResult("");
      if (monitoramento === "AUTOMATICO") {
        console.log("Processo sanc automatico");
        setTimeout(function () { setPainelAtivo(PainelAtivoEnum.SCANNER) }, 3000);
      }
    }
    registrarPresenca();
  }, [scannedResult]);

  function qrCodeValido(qrcode: string | undefined) {
    console.log("QrCode Lido:" + qrcode)
    if (!qrcode) {
      return false;
    }
    if (qrcode.length < 5) {
      setError("QrCode Invalido.")
      return false;
    }
    let camposQrCode = qrcode.split('=');
    if (camposQrCode.length == 12) {
      return validarQrCodeCartaoVirtual(camposQrCode);
    }
    if (camposQrCode.length == 5 && camposQrCode[0] == "QrE01") {
      return validarQrCodeCredencial(camposQrCode);
    }
    if (camposQrCode.length == 3) {
      return validarQrCodeColaborador(camposQrCode);
    }
    setError("QrCode Invalido(layout invalido).")
    return false;
  }

  function validarQrCodeColaborador(camposQrCode: string | any[]) {
    if (!camposQrCode[1]) {
      setError("QrCode Colaborador Invalido(cpf).")
      return false;
    }
    if (!camposQrCode[2] || camposQrCode[2].length < 5) {
      setError("QrCode Colaborador Invalido(nome).")
      return false;
    }
    return true;
  }

  function validarQrCodeCredencial(camposQrCode: string | any[]) {
    if (!camposQrCode[2]) {
      setError("QrCode Credencial Invalido(cpf).")
      return false;
    }
    if (!camposQrCode[3] || camposQrCode[3].length < 5) {
      setError("QrCode Credencial Invalido(nome).")
      return false;
    }
    return true;
  }

  function validarQrCodeCartaoVirtual(camposQrCode: string | any[]) {
    if (camposQrCode[1] !== "01") {
      setError("QrCode Cartão Virtual Invalido(versão).")
      return false;
    }
    if (camposQrCode[2].length !== 17) {
      setError("QrCode Cartão Virtual Invalido(carteira).")
      return false;
    }
    if (!camposQrCode[3] || camposQrCode[3].length < 5) {
      setError("QrCode Cartão Virtual Invalido(nome).")
      return false;
    }
    return true;
  }

  function onHideViewConfiguracoes() {
    setError("");
    if (painelAtivo === PainelAtivoEnum.CONFIGURACOES) {
      setPainelAtivo(PainelAtivoEnum.NENHUM);
    } else {
      setPainelAtivo(PainelAtivoEnum.CONFIGURACOES);
    }
  }

  function onStartStopScanner() {
    setError("");
    if (painelAtivo === PainelAtivoEnum.SCANNER) {
      setPainelAtivo(PainelAtivoEnum.NENHUM);
      return;
    }
    setPainelAtivo(PainelAtivoEnum.NENHUM);
    if (isProgramcaoEventoOk()) {
      setPainelAtivo(PainelAtivoEnum.SCANNER);
    }
  }

  function onHideViewListaDePresenca() {
    setError("");
    if (painelAtivo === PainelAtivoEnum.LISTADEPRESENCA) {
      setPainelAtivo(PainelAtivoEnum.NENHUM);
    } else {
      setPainelAtivo(PainelAtivoEnum.LISTADEPRESENCA);
    }
  }

  function onHideViewRegistroManual() {
    setError("");
    if (painelAtivo === PainelAtivoEnum.REGISTROMANUAL) {
      setPainelAtivo(PainelAtivoEnum.NENHUM);
      return;
    }
    setPainelAtivo(PainelAtivoEnum.NENHUM);
    if (isProgramcaoEventoOk()) {
      setPainelAtivo(PainelAtivoEnum.REGISTROMANUAL);
    }

  }

  function isProgramcaoEventoOk() {
    if (!programacao || !programacao.programacaoId) {
      setError("Abra a Tela de Configurações e Selecione a Programação de uma Atividade Evento em Andamento no Momento")
      return false;
    }
    return true;
  }


  return (
    <div>
      <div className="div-barra-horizontal">
        <div className="div-box-item-menu">
          <div className="div-logo-unimed">
            <img src={logoUnimed} className="img-logo-unimed" />
          </div>
        </div>
        <div className="div-box-item-menu">
          <div className="div-box-menu" onClick={() => onHideViewConfiguracoes()} >
            <img src={settingsSvg} className="img-box-menu-svg" />
            <span className="text-box-menu">
              Configurações
            </span>
          </div>
        </div>
        <div className="div-box-item-menu">
          <div className="div-box-menu" onClick={() => onStartStopScanner()} >
            <img src={scanSvg} className="img-box-menu-svg" />
            <span className="text-box-menu">
              {painelAtivo === PainelAtivoEnum.SCANNER ? "Parar" : "Iniciar"} Leitor QrCode
            </span>
          </div>
        </div>
        <div className="div-box-item-menu">
          <div className="div-box-menu" onClick={() => onHideViewListaDePresenca()} >
            <img src={userCheckSvg} className="img-box-menu-svg" />
            <span className="text-box-menu">
              Lista de Presença
            </span>
          </div>
        </div>

        <div className="div-box-item-menu">
          <div className="div-box-menu" onClick={() => onHideViewRegistroManual()} >
            <img src={formSvg} className="img-box-menu-svg" />
            <span className="text-box-menu">
              Registrar Presença
            </span>
          </div>
        </div>

      </div>
      {painelAtivo === PainelAtivoEnum.CONFIGURACOES && <PainelConfiguracoes monitoramento={monitoramento} setMonitoramento={setMonitoramento} setPainelAtivo={setPainelAtivo} programacao={programacao} setProgramacao={setProgramacao} setError={setError}/>}
      {painelAtivo === PainelAtivoEnum.PAINELCRUDEVENTO && <PainelCrudEvento setPainelAtivo={setPainelAtivo} setLoading={setLoading} evento={evento} setEvento={setEvento} />}
      {painelAtivo === PainelAtivoEnum.PAINELFORMEVENTO && <PainelFormEvento setPainelAtivo={setPainelAtivo} setLoading={setLoading} evento={evento} setEvento={setEvento} setError={setError} />}

      {painelAtivo === PainelAtivoEnum.PAINELCRUDGEVPESSOA && <PainelCrudGevPessoa setLoading={setLoading} setPainelAtivo={setPainelAtivo} pessoa={pessoa} setPessoa={setPessoa} setError={setError} />}
      {painelAtivo === PainelAtivoEnum.PAINELFORMGEVPESSOA && <PainelFormGevPessoa setLoading={setLoading} setPainelAtivo={setPainelAtivo} pessoa={pessoa} setError={setError} />}

      {painelAtivo === PainelAtivoEnum.PAINELCRUDPROGRAMACAO && <PainelCrudProgramacao setLoading={setLoading} setPainelAtivo={setPainelAtivo} evento={evento} setProgramacao={setProgramacaoEdit} setError={setError} />}
      {painelAtivo === PainelAtivoEnum.PAINELFORMPROGRAMACAO && <PainelFormProgramacao setLoading={setLoading} setPainelAtivo={setPainelAtivo} evento={evento} programacao={programacaoEdit} setError={setError} />}
    
      {painelAtivo === PainelAtivoEnum.PAINELINSCRICOES && <PainelInscricoes setLoading={setLoading} setPainelAtivo={setPainelAtivo} evento={evento} setError={setError} />}
      {painelAtivo === PainelAtivoEnum.SCANNER && <QrReader setScannedResult={setScannedResult} setError={setError} setPainelAtivo={setPainelAtivo} />}
      {painelAtivo === PainelAtivoEnum.REGISTROPRESENCA && <PainelRegistroPresenca programacao={programacao} registroPresenca={registroPresenca} setPainelAtivo={setPainelAtivo} />}
      {painelAtivo === PainelAtivoEnum.LISTADEPRESENCA && <PainelListaDePresenca  programacao={programacao}  setLoading={setLoading} setPainelAtivo={setPainelAtivo} setError={setError} />}
      {painelAtivo === PainelAtivoEnum.REGISTROMANUAL && <PainelRegistroManual setLoading={setLoading} programacao={programacao} setPainelAtivo={setPainelAtivo} setRegistroPresenca={setRegistroPresenca} setError={setError}/>}

      {loading && <LoadingWait />}
      {error && <div className='row'><span className="alert-error"> {error} </span></div>}
    </div >
  );
}
export default PaginaGestaoEventos;