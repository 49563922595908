import React from "react";
import keycloak from "../KeycloakProvider";
import IEvento from "../interfaces/IEvento";
import IProgramacao from "../interfaces/IProgramacao";
import IGevPessoa from "../interfaces/IGevPessoa";
import IInscricao from "../interfaces/IInscricao";
import IInscricaoRequest from "../interfaces/IInscricaoRequest";
import { IAutoInscricao } from "../interfaces/IAutoInscricao";


const headers = new Headers({
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${keycloak.token}`
});


function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        if (obj[key] && obj[key] != "") {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
    }
    return keyValuePairs.join('&');
}

function getUrlBase() {
    return getPath() + "/api";
};

function getPath() {
    return "";
    // return "http://localhost:8080";
}

const msgError = "Ocorreu um erro. Por favor, entre em contato com o suporte!";

async function callbackApiGet(url) {
    const options = {
        method: "GET",
        headers: headers
    };
    const response = await fetch(url, options);
    if (response.status >= 400 && response.status < 600) {
        let mensagem="";
        try {
            let data = await response.json();
            mensagem = data.message;
        } catch {
            mensagem = msgError;
        }
        throw new Error(mensagem);
    }
    const data = await response.json();
    return data;
}

async function callbackApiPostData(url, requestData) {
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData)
    };
    const response = await fetch(url, options);
    if (response.status >= 400 && response.status < 600) {
        let mensagem="";
        try {
            let data = await response.json();
            mensagem = data.message;
        } catch {
            mensagem = msgError;
        }
        throw new Error(mensagem);
    }
    const data = await response.json();
    return data;
}


async function callbackApiDelete(url) {
    const options = {
        method: "DELETE",
        headers: headers
    };
    const response = await fetch(url, options);
    if (response.status >= 400 && response.status < 600) {
        let mensagem="";
        try {
            let data = await response.json();
            mensagem = data.message;
        } catch {
            mensagem = msgError;
        }
        throw new Error(mensagem);
    }
    const data = await response.json();
    return data;
}



async function buscarFrontParams() {

    const endPoint = `${getUrlBase()}/front-params`;
    const headersParams = new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    });
    const options = {
        method: "GET",
        headers: headersParams
    };
    const response = await fetch(endPoint, options)
    const data = await response.json();
    return data;
}

async function listarPessoasDto(termo) {
    const url = `${getUrlBase()}/gestaoevento/pesquisar-pessoa-por-termo?termo=${termo}`;
    return callbackApiGet(url);
}

async function registrarPresencaPorQrCode(requestData) {
    const url = `${getUrlBase()}/registrar-presenca-qrcode`;
    return callbackApiPostData(url, requestData);
}

async function registrarPresencaPorCpf(requestData) {
    const url = `${getUrlBase()}/registrar-presenca-cpf`;
    return callbackApiPostData(url, requestData);
}

async function listarRegistrosPresencas(filtro) {
    const url = `${getUrlBase()}/gestaoevento/listar-registros-presencas?` + objToQueryString(filtro);
    return callbackApiGet(url);
}

async function salvarEvento(requestData: IEvento) {
    const url = `${getUrlBase()}/evento/salvar`;
    return callbackApiPostData(url, requestData);
}

async function listarEventos(filtro) {
    const url = `${getUrlBase()}/evento/listar?` + objToQueryString(filtro);
    return callbackApiGet(url);
}


async function deletarEvento(evento) {
    const url = `${getUrlBase()}/evento/deletar/${evento.id}`;
    return callbackApiDelete(url);
}


async function exportarInscricoes(filtro) {
    const url = `${getUrlBase()}/gestaoevento/exportar-inscricao-xlsx?` + objToQueryString(filtro);
    return callbackApiGet(url);
}

async function exportarRegistrosPresencasToXlsx(filtro) {
    const url = `${getUrlBase()}/gestaoevento/exportar-registros-presencas-xlsx?` + objToQueryString(filtro);
    return callbackApiGet(url);
}

async function exportarRegistrosPresencasToPdf(filtro) {
    const url = `${getUrlBase()}/gestaoevento/exportar-registros-presencas-pdf?` + objToQueryString(filtro);
    return callbackApiGet(url);
}

async function salvarProgramacao(requestData: IProgramacao) {
    const url = `${getUrlBase()}/programacao/salvar`;
    return callbackApiPostData(url, requestData);
}

async function listarProgramacao(filtro) {
    const url = `${getUrlBase()}/programacao/listar?` + objToQueryString(filtro);
    return callbackApiGet(url);
}

async function deletarProgramacao(programacao) {
    const url = `${getUrlBase()}/programacao/deletar/${programacao.id}`;
    return callbackApiDelete(url);
}


async function listarGruposSeguranca() {
    const url = `${getUrlBase()}/gestaoevento/listar-grupos-seguranca`;
    return callbackApiGet(url);
}

async function listarProgramacoes(filtro) {
    const url = `${getUrlBase()}/gestaoevento/listar-programacoes?` + objToQueryString(filtro);
    return callbackApiGet(url);
}

async function deletarInscricao(inscricao) {
    const url = `${getUrlBase()}/inscricao/deletar/${inscricao.inscricaoId}`;
    return callbackApiDelete(url);
}

async function listarInscricoes(filtro) {
    const url = `${getUrlBase()}/gestaoevento/listar-inscricoes?` + objToQueryString(filtro);
    return callbackApiGet(url);
}


async function getLinkAutoInscricao(eventoId, programacaoId) {
    let url = `${getUrlBase()}/gestaoevento/link-inscricao-evento?eventoId=${eventoId}`;
    if (programacaoId) {
        url = url + "&programacaoId=" + programacaoId
    }
    return callbackApiGet(url);
}

async function deleletarInscricoes(eventoId, programacaoId) {
    let url = `${getUrlBase()}/gestaoevento/deletar-inscricoes?eventoId=${eventoId}`;
    if (programacaoId) {
        url = url + "&programacaoId=" + programacaoId
    }
    return callbackApiDelete(url);
}

async function buscarPlanilhaInscricoesExemplo() {
    const url = `${getUrlBase()}/gestaoevento/planilha-inscricao-exemplo`;
    return callbackApiGet(url);
}

async function salvarPessoa(requestData: IGevPessoa) {
    const url = `${getUrlBase()}/pessoa/salvar`;
    return callbackApiPostData(url, requestData);
}

async function gerarInscricao(requestData: IInscricaoRequest) {
    const url = `${getUrlBase()}/inscricao/gerar`;
    return callbackApiPostData(url, requestData);
}

async function salvarInscricao(requestData: IInscricao) {
    const url = `${getUrlBase()}/inscricao/salvar`;
    return callbackApiPostData(url, requestData);
}

async function buscarPessoaPorCpf(cpf) {
    const url = `${getUrlBase()}/gestaoevento/pesquisar-pessoa-por-cpf/${cpf}`;
    return callbackApiGet(url);
}

async function listarPessoas(filtro) {
    const url = `${getUrlBase()}/pessoa/listar?` + objToQueryString(filtro);
    return callbackApiGet(url);
}


async function deletarPessoa(pessoa) {
    const url = `${getUrlBase()}/pessoa/deletar/${pessoa.id}`;
    return callbackApiDelete(url);
}

async function uploadPlanilhaInscricao(eventoId, programacaoId, formData) {

    let endPoint = `${getUrlBase()}/gestaoevento/upload-planilha-inscricao?eventoId=${eventoId}`;
    if (programacaoId) {
        endPoint = endPoint + "&programacaoId=" + programacaoId
    }

    const uploadHeaders = new Headers({
        'Authorization': `Bearer ${keycloak.token}`
    });

    const options = {
        method: "POST",
        headers: uploadHeaders,
        body: formData
    };
    delete options.headers['Content-Type'];

    const response = await fetch(endPoint, options);
    if (response.status >= 400 && response.status < 600) {
        let mensagem="";
        try {
            let data = await response.json();
            mensagem = data.message;
        } catch {
            mensagem = msgError;
        }
        throw new Error(mensagem);
    }
    const data = await response.json();
    return data;
}

async function salvarArquivo(arquivoId, file) {
    var endPoint = `${getUrlBase()}/pessoa/salvar-arq-img-perfil`;
    if (arquivoId) {
        endPoint = `${getUrlBase()}/pessoa/salvar?arquivoId=${arquivoId}`;
    }
    const formData = new FormData();
    formData.append("file", file);
    const uploadsHeaders = new Headers({
        'Authorization': `Bearer ${keycloak.token}`
    });

    const options = {
        method: "POST",
        headers: uploadsHeaders,
        body: formData
    };
    delete options.headers['Content-Type'];
    const response = await fetch(endPoint, options);
    const data = await response.json();
    return data;
}
async function buscarArquivo(arquivoId) {
    const url = `${getUrlBase()}/pessoa/listar-arq-img-perfil/${arquivoId}`;
    return callbackApiGet(url);
}

async function enviarInscricaoParaParticipante(filtro) {
    const endPoint = `${getUrlBase()}/gestaoevento/enviar-inscricao?` + objToQueryString(filtro);

    const options = {
        method: "POST",
        headers: headers
    };
    const response = await fetch(endPoint, options);
    const data = await response.json();
    return data;
}

async function realizarAutoInscricao(requestData: IAutoInscricao) {
    const url = `${getPath()}/autoinscricao/salvar`;
    return callbackApiPostData(url, requestData);
}


const CallbackApiService = {
    salvarEvento,
    salvarPessoa,
    listarPessoas,
    deletarPessoa,
    listarEventos,
    deletarEvento,
    salvarArquivo,
    buscarArquivo,
    gerarInscricao,
    salvarInscricao,
    listarPessoasDto,
    deletarInscricao,
    listarInscricoes,
    salvarProgramacao,
    buscarFrontParams,
    listarProgramacao,
    exportarInscricoes,
    deletarProgramacao,
    listarProgramacoes,
    buscarPessoaPorCpf,
    deleletarInscricoes,
    getLinkAutoInscricao,
    listarGruposSeguranca,
    registrarPresencaPorCpf,
    uploadPlanilhaInscricao,
    listarRegistrosPresencas,
    registrarPresencaPorQrCode,
    enviarInscricaoParaParticipante,
    buscarPlanilhaInscricoesExemplo,
    exportarRegistrosPresencasToPdf,
    exportarRegistrosPresencasToXlsx,
    realizarAutoInscricao
}

export default CallbackApiService;