import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import PainelAtivoEnum from '../../enums/PainelAtivoEnum.tsx';
import CallbackApiService from "../../services/CallbackApiService.tsx";
import Utils from '../../componentes/Utils/Utils.tsx';
import IRegistroPresencaRequest from '../../interfaces/IRegistroPresencaRequest.tsx';
import SelectPessoaFilter from './SelectPessoaFilter.tsx';
import IPessoaDto from '../../interfaces/IPessoaDto.tsx';

const PainelRegistroManual = ({ setLoading, programacao, setPainelAtivo, setRegistroPresenca, setError }) => {

    const [validated, setValidated] = useState(false);
    const [cpf, setCpf] = useState<string | undefined>("");
    const [nome, setNome] = useState<string | undefined>("");
    const [observacao, setObservacao] = useState<string | undefined>("");
    const [pessoa, setPessoa] = useState<IPessoaDto>({});
    const [cpfInvalido, setCpfInvalido] = useState(false);

    useEffect(() => {
        return setCpf(formatarValidarCPF(cpf));
    }, [cpf]);

    useEffect(() => {
        if (pessoa?.nome) {
            setNome(pessoa.nome);
        }
        if (pessoa?.cpf) {
            setCpf(String(pessoa.cpf));
        }
    }, [pessoa]);

    function formatarValidarCPF(value: string | undefined) {
        setCpfInvalido(true);
        setPessoa({});
        if (!value)
            return value;
        // remove caracteres nao numericos
        let cpf = String(value.replace(/[^0-9]+/g, ""));
        if (Utils.validarCpf(cpf)) {
            setCpfInvalido(false);
        }
        return Utils.formatarDigitos(cpf, "###.###.###-##");
    }


    async function buscarPessoa(cpf: string) {
        try {
            setError("");
            setPessoa({});
            setLoading(true);
            const data = await CallbackApiService.buscarPessoaPorCpf(cpf);
            setPessoa(data);
            if (data.nome) {
                setNome(data.nome);
            }
        } catch (error) {
            console.log("Falha ao buscar dados de pessos cpf:" + cpf + "  Erro:" + error);
        }
        setLoading(false);
    }

    const onSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true && !cpfInvalido) {
            setValidated(true);
            salvar();
        }
    }

    async function salvar() {
        try {
            setLoading(true);
            let requestData = {} as IRegistroPresencaRequest;
            // remove caracteres nao numericos do cpf
            let digitosCpf = String(cpf.replace(/[^0-9]+/g, ""));
            requestData.programacaoId = programacao.programacaoId;
            requestData.cpf = digitosCpf;
            requestData.nome = nome;
            requestData.observacao = observacao;
            let data = await CallbackApiService.registrarPresencaPorCpf(requestData);
            setRegistroPresenca(data);
            setPainelAtivo(PainelAtivoEnum.REGISTROPRESENCA);
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
    }


    function onHidePainel() {
        setPainelAtivo(PainelAtivoEnum.NENHUM);
    }


    return (
        <section className="painel-registro-manual">
            <div className="titulo-painel ">
                <h2>Registrar Presença</h2>
            </div>
            <div className="col-md-8 col-sm-12">
                <div className="box-painel">
                    <Form validated={validated} onSubmit={onSubmit}>
                        <div className="row" >
                            <div className="col-md-6 col-sm-12">
                                <Form.Label>Evento</Form.Label>
                                <Form.Group className="mb-3">
                                    <Form.Control readOnly type="text" value={programacao.descricaoEvento} />
                                </Form.Group>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <Form.Label>Atividade</Form.Label>
                                <Form.Group className="mb-3">
                                    <Form.Control readOnly type="text" value={programacao.atividade} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <Form.Label>Pesquisa</Form.Label>
                            <SelectPessoaFilter selectedPessoa={pessoa} setSelectedPessoa={setPessoa} setLoading={setLoading} setError={setError} />
                        </div>
                        <div className="row" >
                            <Form.Group className="mb-3">
                                <Form.Label>CPF</Form.Label><span className="text-danger font-bold">*</span>
                                <Form.Control type="text" required onChange={(event) => setCpf(event.target.value)} value={cpf} />
                                <Form.Control.Feedback type="invalid">
                                    Por Favor Informe o CPF.
                                </Form.Control.Feedback>
                                {cpfInvalido && <div className="cpf-invalido">CPF inválido</div>}
                            </Form.Group>
                        </div>
                        <div className="row" >
                            <Form.Group className="mb-3">
                                <Form.Label>Nome</Form.Label><span className="text-danger font-bold">*</span>
                                <Form.Control type="text" required onChange={(event) => setNome(event.target.value)} value={nome} />
                                <Form.Control.Feedback type="invalid">
                                    Por Favor Informe o Nome.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="row" >
                            <Form.Group className="mb-3">
                                <Form.Label>Observação</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={(event) => setObservacao(event.target.value)} value={observacao} />
                            </Form.Group>
                        </div>
                        <div className="row" >
                            <div className="col-md-6 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button type="submit" variant="primary" className="botao-custom-unimed" >Registrar Presença </Button>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="d-grid gap-1">
                                    <Button variant="primary" className="botao-custom-unimed" onClick={() => onHidePainel()}>Fechar </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
    );
}

export default PainelRegistroManual;